import { memo } from 'react';
import { cn } from '@divlab/divanui';

import Img from '@UI/Img';
import ProductNoImage from '@Components/ProductNoImage';
import Link from '@Navigation/Link';
import styles from './ProductPreview.module.css';

import type { ProductData, ProductImageData } from '@Types/Product';
import type { FC, HTMLAttributes } from 'react';

export interface ProductPreviewProps extends HTMLAttributes<HTMLDivElement & HTMLAnchorElement> {
  className?: string;
  cnImage?: string;
  image: ProductImageData;
  orientation: ProductData['orientation'];
  link: ProductData['link'];
}

const ProductPreview: FC<ProductPreviewProps> = (props) => {
  const {
    className,
    cnImage,
    image,
    orientation = 'landscape',
    link,
    onClick,
    ...restProps
  } = props;

  return (
    <div
      {...restProps}
      className={cn(className, styles.placeholderPreview, {
        [styles.landscape]: orientation === 'landscape',
        [styles.portrait]: orientation === 'portrait',
      })}
    >
      <Link to={link} onClick={onClick} target='_blank'>
        {image ? (
          <Img
            className={styles.image}
            cnImage={cnImage}
            src={image.src}
            alt={image.alt}
            itemProp='image'
          />
        ) : (
          <ProductNoImage orientation={orientation} />
        )}
      </Link>
    </div>
  );
};

export default memo(ProductPreview);
