import { memo } from 'react';
import { cn } from '@divlab/divanui';

import Preview from './elements/Preview';
import styles from './NanoProductCard.module.css';

import type { ProductData } from '@Types/Product';
import type { FC, HTMLAttributes } from 'react';

export interface NanoProductCardProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  product: ProductData;
  cnPreview?: string;
}

const NanoProductCard: FC<NanoProductCardProps> = (props) => {
  const { className, product, cnPreview, ...restProps } = props;

  return (
    <div {...restProps} className={cn(styles.card, className)} data-testid='product-card'>
      <Preview className={cnPreview} product={product} />
    </div>
  );
};

export default memo(NanoProductCard);
