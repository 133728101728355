import { cn } from '@divlab/divanui';

import { useInfiniteCategory } from '@Queries/useInfiniteCategory';
import useNavigation from '@Navigation/useNavigation';
import useTranslation from '@Queries/useTranslation';
import icon from './icon.svg';
import Img from '@UI/Img';
import styles from './PromocodeTag.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { CouponCodeData } from '@Types/Product';

interface PromocodeTagProps extends HTMLAttributes<HTMLDivElement>, CouponCodeData {
  animation?: boolean;
}

const PromocodeTag: FC<PromocodeTagProps> = (props) => {
  const { className, code, discount, animation, ...restProps } = props;
  const {
    pathname,
    search,
    params: { slug },
  } = useNavigation();
  const { t } = useTranslation();
  const category = useInfiniteCategory({ slug });
  const pageCount = category.data ? category.data.pages.length : 1;

  // Для избавления от рассинхрона анимации в каталоге товаров при погрузке товаров или фильтрации
  const key = pageCount + pathname + search;

  return (
    <div className={cn(styles.tag, className)} key={key} {...restProps}>
      <div className={cn(styles.container, { [styles.withAnimation]: animation })}>
        <div className={styles.textWrapper}>
          <Img className={styles.image} src={icon} alt='' />
          <p className={styles.text}>{code}</p>
        </div>
        <div className={styles.codeWrapper}>
          <div className={styles.discount}>{`-${discount}%`}</div>
          <p className={styles.text}>{t('ui.by-promocode')}</p>
        </div>
      </div>
    </div>
  );
};

export default PromocodeTag;
