import { memo, useCallback } from 'react';
import { cn } from '@divlab/divanui';

import Img from '@UI/Img';
import styles from './ReviewGallery.module.css';

import type { CSSProperties, FC, HTMLAttributes, MouseEvent } from 'react';
import type { ReviewPhotoData } from '@Types/Review';

export interface ReviewGalleryProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  photos: ReviewPhotoData[];
  count: number;
  onClickImage?: (e: MouseEvent, photoId: number) => void;
}

const ReviewGallery: FC<ReviewGalleryProps> = (props) => {
  const { className, photos, count, onClickImage, ...restProps } = props;
  const restCount = photos.length - count;

  const handleClickReviewImage = useCallback(
    (e: MouseEvent, photoId: number) => {
      if (window.cancelClick) return;
      if (onClickImage) onClickImage(e, photoId);
    },
    [onClickImage],
  );

  return (
    <div
      {...restProps}
      className={cn(styles.wrapperGallery, className)}
      style={{ '--count': `${count}` } as CSSProperties}
    >
      {photos.slice(0, count).map((photo, ind) => (
        <div
          className={styles.photoWrapper}
          onClick={(e) => handleClickReviewImage(e, photo.id)}
          key={photo.id}
        >
          <Img className={styles.photo} cnImage={styles.cnPhoto} src={photo.image} />
          {ind === count - 1 && restCount > 0 && (
            <>
              <div className={cn(styles.lastPhoto, styles.countRest)}>+{restCount}</div>
              <div className={cn(styles.lastPhoto, styles.background, styles.cnPhoto)}></div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default memo(ReviewGallery);
