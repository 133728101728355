import { memo, useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { cn, Price } from '@divlab/divanui';

import { useRequest } from '@Contexts/Request';
import Link from '@Navigation/Link';
import NanoProductCard from '@Components/NanoProductCard';
import getProductName from '@Utils/getProductName';
import ReviewGallery from './elems/ReviewGallery';
import styles from './ReviewInCatalog.module.css';

import type { CSSProperties, FC, HTMLAttributes, MouseEvent } from 'react';
import type { ReviewData } from '@Types/Review';

export interface ReviewInCatalogProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  review: ReviewData;
  onClickImage?: (e: MouseEvent, photoId: number) => void;
  //TODO: Нужно только на время тестирования разной вёрстки в разных категориях, потом можно убрать
  isFullyClickable: boolean;
}

const ReviewInCatalog: FC<ReviewInCatalogProps> = (props) => {
  const { className, review, onClickImage, isFullyClickable, ...restProps } = props;
  const { isUzb } = useRequest();
  const hasPhotos = review.photos.length > 0;
  const ref = useRef<HTMLDivElement>(null);
  const [linesCount, setLinesCount] = useState(1);
  const [height, setHeight] = useState(100);

  const handleResize = useCallback(() => {
    if (!ref.current) return;

    const rect = ref.current.getBoundingClientRect();
    const computedStyles = window.getComputedStyle(ref.current);
    const lineHeight = +computedStyles.lineHeight.replace('px', '');
    const newLinesCount = Math.floor(rect.height / lineHeight);

    setLinesCount(newLinesCount);
    setHeight(newLinesCount * lineHeight);
  }, []);

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const name = useMemo(() => {
    const newName = review.author.trim() || 'Гость';
    const firstTwoWords = newName.split(' ').slice(0, 2);
    const initials = firstTwoWords.map((word) => word[0].toUpperCase()).join('');
    const shortName = firstTwoWords[0] + (firstTwoWords[1] ? ` ${firstTwoWords[1][0]}.` : '');
    return {
      initials,
      shortName,
    };
  }, [review.author]);

  return (
    <div
      {...restProps}
      className={cn(styles.reviewWrapper, className)}
      itemProp='review'
      itemScope
      itemType='http://schema.org/Review'
    >
      <div className={styles.review}>
        <Link view='primary' to={isFullyClickable ? review.product.link : ''}>
          <div className={styles.head}>
            <div>
              <NanoProductCard cnPreview={styles.productImage} product={review.product} />
            </div>
            <div className={styles.productInfo}>
              <Link className={styles.productName} to={isFullyClickable ? '' : review.product.link}>
                {getProductName(review.product)}
              </Link>
              <div className={styles.containerPrices}>
                <Price
                  className={cn(styles.actualPrice, {
                    [styles.promo]: review.product.promo,
                  })}
                  price={review.product.price.actual}
                  itemProp='price'
                  content={review.product.price.actual.toString()}
                />
                {review.product.price.expired > 0 && (
                  <Price
                    expired
                    className={styles.expiredPrice}
                    price={review.product.price.expired}
                    withoutCurrency={isUzb}
                  />
                )}
              </div>
            </div>
          </div>
        </Link>

        <div className={styles.content}>
          <div className={styles.textWrapper} ref={ref}>
            <Link view='primary' to={isFullyClickable ? review.product.link : ''}>
              <div
                itemProp='reviewBody'
                style={
                  { '--lines-count': `${linesCount}`, '--height': `${height}` } as CSSProperties
                }
                className={styles.text}
              >
                {review.text}
              </div>
            </Link>
          </div>

          {hasPhotos && (
            <ReviewGallery
              photos={review.photos}
              count={2}
              onClickImage={onClickImage}
              className={styles.reviewGallery}
            />
          )}

          <div
            className={styles.name}
            itemProp='author'
            itemScope
            itemType='http://schema.org/Person'
          >
            <span itemProp='name'>{name.shortName}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ReviewInCatalog);
