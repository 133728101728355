import fetchSamples from './fetchSamples';
import getInfoByParams from './getInfoByParams';
import getProductVariantInfo from './getProductVariantInfo';
import getProducts from './getProducts';
import getOnboardingInfo from './getOnboardingInfo';
import getDeliveryDate from './getDeliveryDate';
import getConfigurator from './getConfigurator';
import getFabricSamples from './getFabricSamples';
import getSetItem from './getSetItem';
import getSetPage from './getSetPage';
import getDeliveryPickup from './getDeliveryPickup';
import getProductSellPoint from './getProductSellPoint';

export default {
  getDeliveryDate,
  fetchSamples,
  getInfoByParams,
  getProductVariantInfo,
  getProducts,
  getOnboardingInfo,
  getConfigurator,
  getFabricSamples,
  getSetItem,
  getSetPage,
  getDeliveryPickup,
  getProductSellPoint,
};
