import { useCallback, useState, memo, cloneElement } from 'react';
import { cn, GalleryWithProgress, NavArrows } from '@divlab/divanui';

import useMedias from '@Hooks/useMedias';
import Section from '@Components/Section';
import styles from './SectionWithGallery.module.css';

import type { SectionProps } from '@Components/Section';
import type { FC, ReactElement } from 'react';
import type { ProgressOptions } from '@divlab/divanui';

export interface SectionWithGalleryProps extends SectionProps {
  children: ReactElement[];
  cnSection?: string;
  cnGalleryWrapper?: string;
}

const SectionWithGallery: FC<SectionWithGalleryProps> = (props) => {
  const { title, children, cnSection, cnGalleryWrapper, ...restProps } = props;
  const [track, setTrack] = useState<ProgressOptions>(null);
  const [slide, setSlide] = useState(0);
  const { isMobileM } = useMedias();

  const normalizeSlide = useCallback(
    (value: number) => {
      if (value < 0) return 0;
      if (value > children.length) return children.length;

      return value;
    },
    [children.length],
  );

  const handleChangeCurrent = useCallback(({ current }) => {
    setSlide(current);
  }, []);

  const handleChangeProgress = useCallback((opts: ProgressOptions) => {
    setTrack(opts);
  }, []);

  const handlePrev = useCallback(() => {
    setSlide((prev) => normalizeSlide(prev - 1));
  }, [normalizeSlide]);

  const handleNext = useCallback(() => {
    if (track && track.finished) return;

    setSlide((prev) => normalizeSlide(prev + 1));
  }, [normalizeSlide, track]);

  return (
    <div {...restProps}>
      <Section
        className={cn(styles.section, cnSection)}
        title={title}
        arrows={
          track?.width < 100 &&
          !isMobileM && (
            <div>
              <NavArrows onPrev={handlePrev} onNext={handleNext} />
            </div>
          )
        }
      >
        <div className={cn(styles.wrapperGallery, cnGalleryWrapper)}>
          <GalleryWithProgress
            cnGallery={styles.gallery}
            cnTrack={styles.progressBar}
            slideIndex={slide}
            onChangeCurrent={handleChangeCurrent}
            onChangeProgress={handleChangeProgress}
          >
            {children.map((child) => {
              return cloneElement(child, {
                ...child.props,
                className: cn(styles.slide, child.props.className),
              });
            })}
          </GalleryWithProgress>
        </div>
      </Section>
    </div>
  );
};

export default memo(SectionWithGallery);
