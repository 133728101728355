import { memo } from 'react';
import { cn } from '@divlab/divanui';

import Link from '@Navigation/Link';
import ProductImage from '@Components/ProductImage';
import styles from './Preview.module.css';

import type { ProductData } from '@Types/Product';
import type { FC, HTMLAttributes } from 'react';

export interface PreviewProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  product?: ProductData;
  onChangeSlide?: (slide: number) => void;
}

const Preview: FC<PreviewProps> = (props) => {
  const { className, product, ...restProps } = props;
  const images = product.images || [];
  const [firstImage] = images;

  return (
    <div
      {...restProps}
      className={cn(
        styles.preview,
        {
          [styles.landscape]: product.orientation === 'landscape',
          [styles.portrait]: product.orientation === 'portrait',
        },
        className,
      )}
    >
      <Link to={product.link} aria-label={product.name}>
        <div className={styles.content}>
          <ProductImage className={styles.image} src={firstImage?.src} />
        </div>
      </Link>
    </div>
  );
};

export default memo(Preview);
